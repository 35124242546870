import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers"

import BookingSchedules from "../booking/schedules";

import "../config/i18n"

// Stimulus controllers
window.Stimulus = Application.start()
const controllers = import.meta.glob('../controllers/**/*_controller.{js,jsx}', { eager: true })
registerControllers(window.Stimulus, controllers)

// React components
window.Fageda = window.Fageda || {};
window.Fageda.ReactComponents = { ...{ BookingSchedules } };
